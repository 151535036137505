// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import {initBootstrap} from "./bootstrap.js";
import {initWebsite} from "./initWebsite.js";
import {initCards} from "./cards.js";

import Splide from "../../node_modules/@splidejs/splide/dist/js/splide.min.js";

// Loading bootstrap with optional features
initBootstrap({
  tooltip: false,
  popover: false,
  toasts: false,
});

initWebsite();
initCards();

new Splide( '.splide' ).mount();

// Your app code
console.log(`Hello ${process.env.HELLO}`);
