function isInViewport(element) {
  if (!element) {
    return
  }

  var rect = element.getBoundingClientRect();
  var windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  var elemTop = rect.top;
  var elemBottom = rect.bottom;
  var elemHeight = rect.height;
  var visibleHeight = Math.max(0, Math.min(elemHeight, windowHeight - elemTop, elemBottom));
  var visiblePercent = visibleHeight / elemHeight * 100;
  if (element.id === "test") {
    console.log({element, visiblePercent, visibleHeight})
  }
  return visiblePercent > 30;
}

function isElementPassed(element) {
  // Récupérer la position de l'élément
  const elementTop = element.getBoundingClientRect().top;

  // Récupérer la hauteur de la fenêtre
  const windowHeight = window.innerHeight;

  // Calculer la position à partir de laquelle l'élément est considéré comme atteint
  const elementPassedPosition = windowHeight * 0.5;

  // Vérifier si l'élément est atteint
  return elementTop <= elementPassedPosition;
}


export const updateMenu = () => {
  const elements = document.querySelectorAll("[data-menu]");
  let applied = false;
  for (let i = elements.length - 1; i >= 0; i--) {
    const el = elements[i];
    el.classList.remove("active");
    if (!applied) {
      if (isElementPassed(document.getElementById(`${el.dataset.menu}`))) {
        el.classList.add("active");
        applied = true;
      }
    }
  }

}

export const initWebsite = function () {
  
  var isScrolling = false;

  
  // Fonction pour ajouter ou supprimer la classe "in-viewport"
  function updateViewport() {
    var elements = document.querySelectorAll('.animated');
    elements.forEach(function(element) {
      if (isInViewport(element)) {
        if (!element.classList.contains('in-viewport')) {
          element.classList.add('in-viewport');
        }
        if (element.classList.contains('not-in-viewport')) {
          element.classList.remove('not-in-viewport');
        }
      } else {
        if (element.classList.contains('in-viewport')) {
          element.classList.remove('in-viewport');
        }
        if (!element.classList.contains('not-in-viewport')) {
          element.classList.add('not-in-viewport');
        }
      }
    });
  }

  

  function throttleScroll() {
    var body = document.querySelector('body');
    var scrolled = document.documentElement.scrollTop > 0;
    if (scrolled) {
      body.classList.add('scrolled');
    } else {
      body.classList.remove('scrolled');
    }
    isScrolling = false;
  }


  // Événement de défilement de la fenêtre
  var scrolling = false;
  window.addEventListener('scroll', function() {
    if (!scrolling) {
      window.requestAnimationFrame(function() {
        throttleScroll();
        updateViewport();
        updateMenu();
        scrolling = false;
      });
    }
    scrolling = true;
  });



  window.addEventListener('load', function() {
    throttleScroll();
    updateViewport();
    updateMenu();
  });

}
