import VanillaTilt from "vanilla-tilt";
import isMobile from "is-mobile";

export const initCards = () => {
    if (!isMobile()) {
        VanillaTilt.init(document.querySelectorAll(".card-3d"), {
            max: 10,
            speed: 400,
            scale: 1.05,
            glare: 1,
            "max-glare": 0.1
        });
    }
}